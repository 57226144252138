/* eslint-disable no-param-reassign  */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useGlobalState as GenericPageGlobalState } from '../../globalStates/GenericPageState';
import PageProvider from '../../contexts/page-context';
import HeadMeta from '../HeadMeta';
import SegmentPage from '../SegmentPage';
import GenericPageComponents from '../GenericPageComponents';

const propTypes = {
  pageData: PropTypes.shape({
    contentSlots: PropTypes.array,
    searchTitle: PropTypes.string,
    searchDescription: PropTypes.string,
    openGraphTitle: PropTypes.string,
    openGraphDescription: PropTypes.string,
    entryTitle: PropTypes.string,
    analyticsName: PropTypes.string,
    externalId: PropTypes.string,
    contentfulId: PropTypes.string,
    contentTypeId: PropTypes.string,
    revision: PropTypes.number,
    openGraphImage: PropTypes.shape({}),
    twitterCardImage: PropTypes.shape({}),
    theme: PropTypes.string,
    updatedAt: PropTypes.string,
    patronusData: PropTypes.array,
  }),
  isModal: PropTypes.bool,
};

const defaultPageData = {
  entryTitle: '',
  contentSlots: [],
};

const GenericPage = ({ pageData = defaultPageData, isModal = false }) => {
  const [, setIsGenericPage] = GenericPageGlobalState('isGenericPage');
  const [, setOnVerticallyIndexedPage] = GenericPageGlobalState('onVerticallyIndexedPage');
  const [, setComponentOrder] = GenericPageGlobalState('componentOrder');
  const {
    searchTitle,
    searchDescription,
    openGraphTitle,
    openGraphDescription,
    contentSlots,
    openGraphImage,
    twitterCardImage,
    theme,
    contentfulId,
    contentTypeId,
    entryTitle,
    externalId,
    revision,
    updatedAt,
  } = pageData;
  const ogImage = openGraphImage?.image?.file?.url ?? '';
  const robots = pageData?.metaTag ?? '';
  const canonicalUrl = pageData?.canonicalUrl ?? `/${pageData.externalId}`;
  const twitterImage = twitterCardImage?.image?.file?.url ?? '';
  const analyticsName = pageData.analyticsName || pageData.entryTitle;
  const pageKey = analyticsName.replace(' ', '');

  const containsVideo = contentSlots.some((slot) => {
    return (
      slot.contentTypeId === 'video' ||
      (slot.contentTypeId === 'hero' && slot.mainVideo) ||
      slot.text?.includes('https://www.youtube-nocookie.com/embed/') ||
      slot.text?.includes('https://www.youtube.com/embed/')
    );
  });

  // Always start at the top of the page
  useEffect(() => {
    window.scrollTo(0, 0);

    // bool used to differentiate components when used on both GenericPage or otherwise
    setIsGenericPage(true);
    setOnVerticallyIndexedPage(true);
    setComponentOrder(contentSlots.map((slot) => slot.contentfulId));

    return () => {
      setIsGenericPage(false);
      setOnVerticallyIndexedPage(false);
      setComponentOrder([]);
    };
  }, [contentSlots, externalId, setComponentOrder, setIsGenericPage, setOnVerticallyIndexedPage]);

  return (
    <>
      <SegmentPage
        key={pageKey}
        pageName={analyticsName}
        otherProps={{
          content_id: contentfulId,
          content_name: entryTitle,
          content_type: contentTypeId,
          content_ver: revision,
          publish_date: updatedAt,
          contains_video: containsVideo,
        }}
      />
      <PageProvider initialPageName={analyticsName}>
        <HeadMeta
          title={searchTitle}
          description={searchDescription}
          ogTitle={openGraphTitle}
          ogDescription={openGraphDescription}
          ogImage={`https:${ogImage}?w=1200&fit=fill&f=top`}
          twitterImage={`https:${twitterImage}?w=1200&fit=fill&f=top`}
          robots={robots}
          canonicalUrl={canonicalUrl}
        />
        <GenericPageComponents
          patronusData={pageData.patronusData}
          contentSlots={contentSlots}
          isModal={isModal}
          theme={theme}
        />
      </PageProvider>
    </>
  );
};

GenericPage.propTypes = propTypes;
export default GenericPage;
